/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../declarations/graphql/types';

import { gql } from '@urql/core';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOUseReferralTokenMutationVariables = Types.Exact<{
  token?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type DTOUseReferralTokenMutation = { __typename?: 'Mutation', useReferralToken: { __typename?: 'Referrer', id: any, refereePoints: number, refereeStatus: Types.DTORefereeStatus, profile?: { __typename?: 'Profile', id: any, nickname: string } | null } };


export const UseReferralTokenMutationDocument = gql`
    mutation UseReferralTokenMutation($token: String) {
  useReferralToken(token: $token) {
    id
    profile {
      id
      nickname
    }
    refereePoints
    refereeStatus
  }
}
    `;

export function useUseReferralTokenMutation() {
  return Urql.useMutation<DTOUseReferralTokenMutation, DTOUseReferralTokenMutationVariables>(UseReferralTokenMutationDocument);
};