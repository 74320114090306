import { ReactNode, Fragment } from "react";
import { cn } from "~/utils";

type Props = {
  children: ReactNode[];
  className?: string;
  dividerClassName?: string;
};

const FriendsSection = ({
  children,
  className,
  dividerClassName
}: Props): JSX.Element => {
  return (
    <section
      className={cn(
        "rounded-24 flex justify-around py-3 min-[380px]:px-4 px-3 bg-white/4 h-[72px] mb-1 backdrop-blur-md",
        className
      )}
    >
      {children.map((child, index) => (
        <Fragment key={index}>
          {child}
          {index < children.length - 1 && (
            <div
              className={cn(
                "w-[1px] h-full bg-white/4 pr-[1px] min-[380px]:mx-3 mx-2",
                dividerClassName
              )}
            >
              &nbsp;
            </div>
          )}
        </Fragment>
      ))}
    </section>
  );
};

export default FriendsSection;
