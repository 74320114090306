import { startFarming } from "./startFarming";
import { claimFarmingReward } from "./claimFarmingReward";
import { boostModel } from "./boostModel";

const mutationsIndex = {
  claimFarmingReward,
  startFarming,
  boostModel
};

export default mutationsIndex;
