import { ReactNode, useEffect, useState } from "react";
import PageWithBackground from "~/components/layouts/PageWithBackground";
import { cn } from "~/utils";

const TIMEOUT_TO_UPDATE_PROGRESS = 400;
const COEFFICIENT_TO_UPDATE_PROGRESS = 0.25;

type Props = {
  isDone: boolean;
  className?: string;
  children?: ReactNode;
};

export const Loader = ({ isDone, className, children }: Props) => {
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    if (isDone) {
      setProgressValue(1);
    }
  }, [isDone]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgressValue(value => {
        const rest = 1 - value;
        const randomAddition =
          Math.random() * rest * COEFFICIENT_TO_UPDATE_PROGRESS;

        return value + randomAddition;
      });
    }, TIMEOUT_TO_UPDATE_PROGRESS);

    return () => clearInterval(intervalId);
  }, []);

  const percent = Math.floor(progressValue * 100);

  return (
    <div
      className={cn(
        className,
        "h-full max-w-page-max m-auto bg-background-octonary"
      )}
    >
      <PageWithBackground className={cn("h-full w-full flex flex-col")}>
        <div className="flex flex-col flex-1 relative">{children}</div>
        <footer className="px-2 pb-4">
          <div className="relative rounded-[20px] overflow-hidden bg-background-primary/[0.04] backdrop-blur-md">
            <div
              className="bg-gradient-loader h-12 bg-[length:48px_48px] animate-loader-go transition-transform"
              style={{ transform: `translate(${percent - 100}%)` }}
            />
            <span className="absolute top-1.5 right-4 t-h2">
              {percent}
              <span className="t-h6 text-foreground-tertiary">%</span>
            </span>
          </div>
        </footer>
      </PageWithBackground>
    </div>
  );
};
