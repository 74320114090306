import { retrieveLaunchParams as retrieveTelegramLaunchParams } from "@telegram-apps/sdk-react";

export const retrieveLaunchParams = () => {
  const result = retrieveTelegramLaunchParams();

  const initDataRaw =
    process.env.NODE_ENV === "development"
      ? import.meta.env.VITE_LOGIN_TELEGRAM_INIT_DATA
      : result.initDataRaw;

  return {
    ...result,
    initDataRaw
  };
};
