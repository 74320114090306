/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../declarations/graphql/types';

import { gql } from '@urql/core';
export type DTOCurrentFarmingTaskFragment = { __typename?: 'FarmingTask', id: any, rate: number, points: number, finishingAt: any, startedAt: any, serverTime: any };

export const CurrentFarmingTaskFragmentDoc = gql`
    fragment CurrentFarmingTaskFragment on FarmingTask {
  id
  rate
  points
  finishingAt
  startedAt
  serverTime
}
    `;