import { DocumentNode } from "graphql";
import isEqual from "lodash.isequal";
import { useCallback, useMemo, useRef, useState } from "react";
import { CombinedError, RequestPolicy, useClient, useQuery } from "urql";

import { RequestState } from "~/constants/requestState";
import wrapQuery from "~/utils/graphql/usePagination/wrapQuery";

import {
  PaginationQuery,
  PaginationQueryNode,
  PaginationVariables,
  UsePaginationResult
} from "./declarations";

const STUB_QUERY_DATA = {
  edges: [],
  totalCount: 0,
  pageInfo: {
    hasNextPage: false
  }
};

interface UsePaginationProps<
  Key extends keyof Query,
  QueryVariables,
  Query extends PaginationQuery<unknown, Key>,
  QueryDataMapped
> {
  query: DocumentNode;
  queryKey: Key;
  requestMapper: (data: PaginationQueryNode<Query, Key>[]) => QueryDataMapped[];
  variables?: Omit<QueryVariables, keyof PaginationVariables>;
  batchSize?: number;
  requestPolicy?: RequestPolicy;
  pause?: boolean;
}

const usePagination = <
  Query extends PaginationQuery<unknown, Key>,
  Key extends keyof Query,
  QueryVariables extends PaginationVariables,
  QueryDataMapped extends Record<string, unknown>
>({
  query,
  queryKey,
  variables,
  batchSize,
  pause,
  requestMapper,
  requestPolicy = "cache-first"
}: UsePaginationProps<
  Key,
  QueryVariables,
  Query,
  QueryDataMapped
>): UsePaginationResult<PaginationQueryNode<Query, Key>, QueryDataMapped> => {
  const [fetchingMore, setFetchingMore] = useState<boolean>(false);
  const [shadowUpdating, setShadowUpdating] = useState<boolean>(false);
  const variablesRef = useRef(variables);
  const graphqlClient = useClient();

  if (!isEqual(variablesRef.current, variables)) {
    variablesRef.current = variables;
  }

  // Hook always return actual data. But if you make request for the next batch and error occurred, data becomes undefined.
  // So we can`t return success object with records in such case. So hook fires with variables according only for the first batch request
  const [{ data, fetching, error }, retry] = useQuery<
    Query,
    PaginationVariables
  >({
    query,
    variables: { ...variablesRef.current, first: batchSize },
    requestPolicy,
    pause
  });

  const startCursor = data && data[queryKey].pageInfo.startCursor;
  const endCursor = data && data[queryKey].pageInfo.endCursor;
  const queryData = data ? data[queryKey] : STUB_QUERY_DATA;
  const records = useMemo(
    () => queryData?.edges?.map(({ node }) => node),
    [queryData.edges]
  );

  const fetch = useCallback(
    async (paginationVariables: PaginationVariables): Promise<void> => {
      await wrapQuery(
        graphqlClient,
        query,
        { ...variablesRef.current, ...paginationVariables },
        { requestPolicy }
      );
    },
    [graphqlClient, query, requestPolicy]
  );

  const fetchMore = useCallback(async (): Promise<void> => {
    setFetchingMore(true);
    await fetch({
      first: batchSize,
      after: endCursor ?? null
    });
    setFetchingMore(false);
  }, [batchSize, endCursor, fetch]);

  const updateBefore = useCallback(async (): Promise<void> => {
    setShadowUpdating(true);
    // TODO: in accordance with the relay rules, when passing a before cursor, only records that are before cursor will be returned, thus the record with passed cursor before will not be updated
    await fetch({
      before: startCursor ?? null
    });
    setShadowUpdating(false);
  }, [fetch, startCursor]);
  const edges = data && data[queryKey].edges;

  const refresh = useCallback(async (): Promise<void> => {
    await fetch({
      first: edges ? Math.min(edges.length, 100) : batchSize //TODO: 100 its max limit for backend, we need todo step by step refresh
    });
  }, [batchSize, edges, fetch]);

  if (fetching || pause) {
    return {
      type: RequestState.Loading
    };
  }

  if (error || !data) {
    const combinedError = error
      ? error
      : new CombinedError({
          networkError: new Error("No error object")
        });

    return {
      type: RequestState.Error,
      error: combinedError,
      retryLastRequest: () => retry()
    };
  }

  const { hasNextPage: hasMore } = queryData.pageInfo;

  if (records.length === 0) {
    return {
      type: RequestState.SuccessEmpty,
      hasMore,
      updating: shadowUpdating,
      fetchMore,
      refresh,
      updateBefore
    };
  }

  return {
    ...queryData,
    type: RequestState.Success,
    totalCount: queryData?.totalCount ?? 0,
    hasMore,
    records,
    recordsMapped: requestMapper(records),
    fetchMore,
    updateBefore,
    fetchingMore,
    refresh,
    updating: shadowUpdating
  };
};

export default usePagination;
