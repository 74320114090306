import { UpdateResolver } from "@urql/exchange-graphcache";
import { gql } from "urql";
import { DTOBoostModelMutation } from "~/components/boost/graphql/boostModelMutation.generated";
import { getMyId } from "~/providers/UrqlProvider/cacheExchange/updates/mutations/getMyId";

export const boostModel: UpdateResolver = (
  result: DTOBoostModelMutation,
  _args,
  cache
) => {
  const fragment = gql`
    fragment _ on Me {
      id
      currentModel {
        ...CurrentModelFragment
      }
    }
  `;

  const myId = getMyId(cache);

  cache.invalidate("Query", "tasks");
  cache.invalidate("Query", "dailyBonuses");
  cache.writeFragment(fragment, {
    id: myId,
    currentModel: result.boostModel
  });
};
