export default function copyToClipboardViaSyncApi(text: string): boolean {
  const tempTextarea = document.createElement("textarea");

  tempTextarea.textContent = text;
  tempTextarea.style.position = "fixed";
  tempTextarea.style.display = "none";
  document.body.appendChild(tempTextarea);
  tempTextarea.select();

  try {
    return document.execCommand("copy"); // Security exception may be thrown by some browsers.
  } catch (_error) {
    console.error("Copy to clipboard failed", _error);

    return false;
  } finally {
    document.body.removeChild(tempTextarea);
  }
}
