import PageWithBackground from "~/components/layouts/PageWithBackground";
import QRCode from "./assets/qrCode.svg?react";
import { useTranslation } from "react-i18next";

export const QRCodeWithLinkToBot = () => {
  const { t } = useTranslation("common");

  return (
    <PageWithBackground className="flex flex-col items-center justify-center relative overflow-hidden h-full w-full m-auto max-w-page-max">
      <div className="w-full h-full max-w-[252px] max-h-[252px] rounded-xl overflow-hidden">
        <QRCode />
      </div>
      <div className="absolute bottom-8 t-h3 w-full max-w-[340px] text-center">
        {t("qrCode.continue")}
      </div>
    </PageWithBackground>
  );
};
