import { useTranslation } from "react-i18next";
import FriendsBonusDescriptionItem from "./FriendsBonusDescriptionItem";
import FriendsSection from "../FriendsSection";

type Props = {
  forPremiumFriend?: number;
  forRegularFriend?: number;
};

const FriendsBonusDescription = ({
  forPremiumFriend = 0,
  forRegularFriend = 0
}: Props): JSX.Element => {
  const { t } = useTranslation("friends");

  return (
    <FriendsSection>
      <FriendsBonusDescriptionItem
        amount={forRegularFriend}
        description={t("bonus.forFriend")}
      />
      <FriendsBonusDescriptionItem
        amount={forPremiumFriend}
        description={t("bonus.forPremiumFriend")}
      />
    </FriendsSection>
  );
};

export default FriendsBonusDescription;
