/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../../../declarations/graphql/types';

import { gql } from '@urql/core';
export type DTOTaskFragment = { __typename?: 'Task', id: any, title: string, description: string, points: number, complexity: Types.DTOTaskComplexity, progress: Types.DTOTaskProgress, action: { __typename: 'InviteFriends', targetFriends: number } | { __typename: 'LevelUp', targetLevel: number } | { __typename: 'SubscribeToInstagram', link: string } | { __typename: 'SubscribeToTelegram', link: string } | { __typename: 'SubscribeToX', link: string } | { __typename: 'SubscribeToYoutube', link: string } | { __typename: 'WatchYoutubeVideo', link: string } };

export const TaskFragmentDoc = gql`
    fragment TaskFragment on Task {
  id
  title
  description
  points
  complexity
  progress
  action {
    ... on SubscribeToTelegram {
      __typename
      link
    }
    ... on SubscribeToX {
      __typename
      link
    }
    ... on SubscribeToYoutube {
      __typename
      link
    }
    ... on SubscribeToInstagram {
      __typename
      link
    }
    ... on WatchYoutubeVideo {
      __typename
      link
    }
    ... on LevelUp {
      __typename
      targetLevel
    }
    ... on InviteFriends {
      __typename
      targetFriends
    }
  }
}
    `;