import { Events } from "~/constants/events";

export function isEmpty(value: unknown): boolean {
  return (
    value == null ||
    value == undefined || // From standard.js: Always use === - but obj == null is allowed to check null || undefined
    (typeof value === "object" &&
      value !== null &&
      Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
}

export default function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  if (value === null || value === undefined) {
    return false;
  }

  return true;
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === "boolean";
}

export function isNotNull<T>(value: T | null | undefined): value is T {
  return value != null;
}

export function isObject(
  value: unknown
): value is Record<string | number | symbol, unknown> {
  return typeof value === "object";
}

export function isNumber(value: unknown): value is number {
  return typeof value === "number";
}

export function isSafari(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();

  return (
    userAgent.indexOf("safari") !== -1 && userAgent.indexOf("chrome") === -1
  );
}

export function isString(str: unknown): str is string {
  return typeof str === "string";
}

export function isNotNullable<T>(value: T | null | undefined): value is T {
  return value != null;
}

export function isTouchDevice(): boolean {
  try {
    document.createEvent(Events.TouchEvent);
    return true;
  } catch (_error) {
    return false;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(value: unknown): value is Function {
  return typeof value === "function";
}
