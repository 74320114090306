/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../declarations/graphql/types';

import { gql } from '@urql/core';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOReferralRewardsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DTOReferralRewardsQuery = { __typename?: 'Query', referralRewards: { __typename?: 'ReferralRewards', invitePremiumFriend: number, inviteRegularFriend: number } };


export const ReferralRewardsQueryDocument = gql`
    query ReferralRewardsQuery {
  referralRewards {
    invitePremiumFriend
    inviteRegularFriend
  }
}
    `;

export function useReferralRewardsQuery(options?: Omit<Urql.UseQueryArgs<DTOReferralRewardsQueryVariables>, 'query'>) {
  return Urql.useQuery<DTOReferralRewardsQuery, DTOReferralRewardsQueryVariables>({ query: ReferralRewardsQueryDocument, ...options });
};