import { ReactNode } from "react";
import { formatTokens } from "~/components/balance/Tokens/utils";

type Props = {
  label: string;
  value: number;
  icon: ReactNode;
};

const FriendsStatsItem = ({ label, value, icon }: Props): JSX.Element => {
  return (
    <div className="flex items-center w-full gap-[10px]">
      {icon}
      <div className="flex flex-col gap-[0.5]">
        <span className="t-p-quaternary text-foreground-secondary">
          {label}
        </span>
        <span className="t-h6 font-regular">{formatTokens(value)}</span>
      </div>
    </div>
  );
};

export default FriendsStatsItem;
