export const TOKEN_FORMAT_CURRENCY = "USD";
export const TOKEN_FORMAT_LOCALE = "en";
export const NUMBER_TO_SHORTEN_FROM = 1e4;
export const SHORTENED_TOKEN_SUFFIXES = [
  { value: 1e12, suffix: "t" },
  { value: 1e9, suffix: "b" },
  { value: 1e6, suffix: "m" },
  { value: 1e3, suffix: "k" },
  { value: 1, suffix: "" }
] as const;
