/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../declarations/graphql/types';

import { gql } from '@urql/core';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOCreateOrGetReferralTokenMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DTOCreateOrGetReferralTokenMutation = { __typename?: 'Mutation', createOrGetReferralToken: string };


export const CreateOrGetReferralTokenMutationDocument = gql`
    mutation CreateOrGetReferralTokenMutation {
  createOrGetReferralToken
}
    `;

export function useCreateOrGetReferralTokenMutation() {
  return Urql.useMutation<DTOCreateOrGetReferralTokenMutation, DTOCreateOrGetReferralTokenMutationVariables>(CreateOrGetReferralTokenMutationDocument);
};