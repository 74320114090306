import { Cache } from "@urql/exchange-graphcache";
import {
  DTOMeQuery,
  MeQueryDocument
} from "~/graphql/useMe/graphql/meQuery.generated";

export const getMyId = (cache: Cache): string => {
  const data = cache.readQuery<DTOMeQuery>({
    query: MeQueryDocument
  });

  return data?.me.id ?? null;
};
