import { useEffect, useMemo } from "react";
import { useCreateOrGetReferralTokenMutation } from "../graphql/createOrGetReferralTokenMutation.generated";
import { START_QUERY_PARAM, TELEGRAM_LINK_BASE } from "~/constants/links";

export default function useReferralLink(): string | null {
  const [{ data: referralToken }, getReferralToken] =
    useCreateOrGetReferralTokenMutation();

  const referralLink = useMemo(() => {
    if (referralToken?.createOrGetReferralToken) {
      return [
        TELEGRAM_LINK_BASE,
        import.meta.env.VITE_TELEGRAM_BOT,
        "/",
        import.meta.env.VITE_TELEGRAM_APP,
        "?",
        START_QUERY_PARAM,
        "=",
        referralToken?.createOrGetReferralToken
      ].join("");
    }
    return null;
  }, [referralToken]);

  // TODO: Check execution duplication for prod env
  useEffect(() => {
    getReferralToken({});
  }, [getReferralToken]);

  return referralLink;
}
