import { cacheExchange } from "@urql/exchange-graphcache";
import { IntrospectionQuery } from "graphql";

import optimistic from "./optimistic";
import resolvers from "./resolvers";
import schema from "./schema.json";
import mutations from "./updates/mutations";
import subscriptions from "./updates/subscriptions";

export default cacheExchange({
  /* Type inference for the JSON doesn't work well */
  schema: schema as unknown as IntrospectionQuery,
  updates: {
    Mutation: mutations,
    Subscription: subscriptions
  },
  optimistic,
  resolvers,
  keys: {
    UserModel: ({ id }) => id as string,
    ModelLevel: ({ id }) => id as string
  }
});
