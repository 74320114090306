import { createImmortalSpinePlayer } from "./utils";
import brainAtlas from "./assets/brain/brain.atlas?base64";
import brainSkeleton from "./assets/brain/brain.json?base64";
import brainImage from "./assets/brain/brain.png?base64";

export const FARMING_ANIMATION_PAYLOAD = createImmortalSpinePlayer({
  animationName: "1_nobrain",
  skeletonUrl: "brain.json",
  atlasUrl: "brain.atlas",
  rawDataURIs: {
    "brain.json": `data:application/json;base64,${brainSkeleton}`,
    "brain.atlas": `data:application/octet-stream;base64,${brainAtlas}`,
    "brain.png": `data:image/png;base64,${brainImage}`
  }
});
