/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../declarations/graphql/types';

import { gql } from '@urql/core';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOLoginMutationVariables = Types.Exact<{
  initData: Types.Scalars['String']['input'];
}>;


export type DTOLoginMutation = { __typename?: 'Mutation', loginByTelegramInitData: any };


export const LoginMutationDocument = gql`
    mutation LoginMutation($initData: String!) {
  loginByTelegramInitData(initData: $initData)
}
    `;

export function useLoginMutation() {
  return Urql.useMutation<DTOLoginMutation, DTOLoginMutationVariables>(LoginMutationDocument);
};