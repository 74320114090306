import { MILLISECONDS_IN_SECOND } from "~/constants/time";

export const NETWORK_CODES = {
  ok: 200,
  found: 302,
  temporaryRedirect: 307,
  notFound: 404
};

export const NETWORK_API_CODES = {
  unauthorized: "notAuthenticated",
  forbidden: "notAuthorized",
  internalServerError: "server.internalError"
};

export enum NetworkProtocols {
  http = "http:",
  https = "https:"
}

export const COMMON_FAKE_REQUEST_TIMEOUT = 0.5 * MILLISECONDS_IN_SECOND;
