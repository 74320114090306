import { requestPolicyExchange } from "@urql/exchange-request-policy";
import { retryExchange } from "@urql/exchange-retry";
import { authExchange, AuthUtilities } from "@urql/exchange-auth";
import { ReactNode } from "react";
import { Client, CombinedError, fetchExchange, Provider } from "urql";
import { NETWORK_API_CODES } from "~/constants/network";
import getGraphQLErrorCode from "~/utils/graphql/errors/getGraphQLErrorCode";
import cacheExchange from "./cacheExchange";
import { LoginMutationDocument } from "~/graphql/useLogin/graphql/loginMutation.generated";
import { retrieveLaunchParams } from "~/utils/telegram/retrieveLaunchParams";

const UNAUTHENTICATED_CODE = "Unauthenticated";

const client = new Client({
  url: import.meta.env.VITE_API_URL,
  fetchOptions: {
    credentials: "include"
  },
  exchanges: [
    cacheExchange,
    retryExchange({
      initialDelayMs: 1000,
      maxDelayMs: 15000,
      randomDelay: true,
      maxNumberAttempts: 2,
      retryIf: (error: CombinedError) =>
        NETWORK_API_CODES.internalServerError === getGraphQLErrorCode(error)
    }),
    authExchange(async (utils: AuthUtilities) => {
      return {
        didAuthError(error: CombinedError) {
          return error.graphQLErrors.some(
            ({ extensions }) => extensions?.code === UNAUTHENTICATED_CODE
          );
        },
        async refreshAuth() {
          const { initDataRaw } = retrieveLaunchParams();

          const response = await utils.mutate(LoginMutationDocument, {
            initData: initDataRaw
          });

          if (response.error != null) {
            throw new Error("Can't login");
          }
        },
        addAuthToOperation(operation) {
          return operation;
        }
      };
    }),
    requestPolicyExchange({}),
    fetchExchange
  ]
});

type Props = {
  children: ReactNode;
};

export const UrqlProvider = ({ children }: Props): JSX.Element => {
  return <Provider value={client}>{children}</Provider>;
};
