import { UseQueryState } from "urql";
import { DTOMeQuery } from "../useMe/graphql/meQuery.generated";
import { Exact, RefereeStatus } from "~/declarations/graphql/types";
import { useUseReferralTokenMutation } from "./graphql/useReferralTokenMutation.generated";
import { useInitData } from "@telegram-apps/sdk-react";
import { useEffect } from "react";

type MeResult = UseQueryState<
  DTOMeQuery,
  Exact<{
    [key: string]: never;
  }>
>;

export default function useReferer(me: MeResult) {
  const [, referralTokenMutation] = useUseReferralTokenMutation();
  const initData = useInitData();
  const status = me.data?.me.referrer.refereeStatus;
  const isPendingStatus = status === RefereeStatus.Pending;
  const isNoopStatus = status === RefereeStatus.Noop;

  useEffect(() => {
    if (isPendingStatus || (isNoopStatus && initData?.startParam)) {
      referralTokenMutation({ token: initData?.startParam });
    }
  }, [
    initData?.startParam,
    isNoopStatus,
    isPendingStatus,
    referralTokenMutation
  ]);
}
