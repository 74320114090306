import copyToClipboardViaAsyncApi from "./copyToClipboardViaAsyncApi";
import copyToClipboardViaSyncApi from "./copyToClipboardViaSyncApi";

export default async function copyToClipboard(text: string): Promise<boolean> {
  if (navigator && navigator.clipboard) {
    return copyToClipboardViaAsyncApi(text);
  }

  if (
    document.queryCommandSupported &&
    document.queryCommandSupported("copy")
  ) {
    return copyToClipboardViaSyncApi(text);
  }

  return false;
}
