import { UpdateResolver } from "@urql/exchange-graphcache";
import { gql } from "urql";
import { getMyId } from "~/providers/UrqlProvider/cacheExchange/updates/mutations/getMyId";

export const claimFarmingReward: UpdateResolver = (_result, _args, cache) => {
  const fragment = gql`
    fragment _ on Me {
      id
      currentFarmingTask {
        ...CurrentFarmingTaskFragment
      }
    }
  `;

  const id = getMyId(cache);

  cache.writeFragment(fragment, { id, currentFarmingTask: null });
};
