import { forwardRef } from "react";

import { OnScrollToComponentProps } from "./OnScrollTo";
import OnScrollToDotsLoader from "./OnScrollToDotsLoader";
import { cn } from "~/utils";

const OnScrollToDefaultComponent = (
  { className, loading }: OnScrollToComponentProps<HTMLDivElement>,
  ref: React.Ref<HTMLDivElement>
): JSX.Element => (
  <div className={cn("flex justify-center w-full p-5", className)} ref={ref}>
    {loading && <OnScrollToDotsLoader />}
  </div>
);
export default forwardRef(OnScrollToDefaultComponent);
