import { CombinedError } from "urql/core";

import { isEmpty } from "~/utils/common";

export default function getGraphQLErrorCode(
  error?: CombinedError | null
): string | null {
  if (error && !isEmpty(error.graphQLErrors)) {
    const errorMessageObject = error.graphQLErrors[0];

    return (errorMessageObject?.extensions?.code as string) ?? null;
  }

  return null;
}
