import { FormatTokensFunction } from "./types";
import {
  TOKEN_FORMAT_CURRENCY,
  SHORTENED_TOKEN_SUFFIXES,
  NUMBER_TO_SHORTEN_FROM,
  TOKEN_FORMAT_LOCALE
} from "./constants";

export const formatTokens: FormatTokensFunction = (
  count,
  { isShortened = true, digitsAfterPoint = 2 } = {}
) => {
  const formatter = new Intl.NumberFormat(TOKEN_FORMAT_LOCALE, {
    currency: TOKEN_FORMAT_CURRENCY
  });

  if (isShortened && count >= NUMBER_TO_SHORTEN_FROM) {
    const { shortenedCount, suffix } = getShortenedTokenCount(
      count,
      digitsAfterPoint
    );
    const formattedNumber = formatter.format(shortenedCount);

    return `${formattedNumber}${suffix}`;
  }

  const formattedNumber = formatter.format(count);

  return formattedNumber;
};

const getShortenedTokenCount = (count: number, digitsAfterPoint: number) => {
  for (const { suffix, value } of SHORTENED_TOKEN_SUFFIXES) {
    if (count >= value) {
      const decimalCount = Math.pow(10, digitsAfterPoint);
      const shortenedCount =
        Math.floor((count / value) * decimalCount) / decimalCount;

      return {
        shortenedCount,
        suffix
      };
    }
  }

  return {
    shortenedCount: count,
    suffix: ""
  };
};
