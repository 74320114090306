import { UpdateResolver } from "@urql/exchange-graphcache";
import { gql } from "urql";
import { getMyId } from "~/providers/UrqlProvider/cacheExchange/updates/mutations/getMyId";

export const startFarming: UpdateResolver = (result, _args, cache) => {
  const fragment = gql`
    fragment _ on Me {
      id
      currentFarmingTask {
        ...CurrentFarmingTaskFragment
      }
    }
  `;

  const id = getMyId(cache);
  const currentFarmingTask = result.startFarming;

  cache.writeFragment(fragment, {
    id,
    currentFarmingTask
  });
};
