import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "~/utils";

const wrapperVariants = cva(`inline-flex h-fit rounded-full`, {
  variants: {
    variant: {
      accent: "bg-gradient-primary-light p-[0.5px]",
      primary: "bg-gradient-secondary-light p-[0.5px]",
      default: "bg-none"
    },
    disabled: {
      true: "bg-none",
      false: ""
    },
    fullWidth: {
      true: "w-full",
      false: ""
    },
    size: {
      xl: "h-[64px]",
      lg: "h-[56px]",
      md: "h-[48px]",
      sm: "h-[40px]"
    }
  }
});

const buttonVariants = cva(
  `
    inline-flex items-center justify-center
    focus:outline-none rounded-full border-none
    uppercase font-semibold t-button-tertiary whitespace-nowrap
    disabled:pointer-events-none disabled:bg-white/8 disabled:bg-none disabled:text-foreground-secondary
    [&>svg]:fill-current [&>svg]:text-foreground-primary [&>svg]:disabled:text-foreground-secondary
  `,
  {
    variants: {
      variant: {
        accent:
          "bg-gradient-primary hover:bg-primary/90 active:bg-primary-dark active:bg-none",
        primary:
          "bg-gradient-secondary active:bg-secondary-dark active:bg-none text-foreground-quaternary hover:bg-secondary/90",
        default:
          "bg-background-senary active:bg-background-quinary hover:bg-senary/90"
      },
      fullWidth: {
        true: "w-full",
        false: ""
      },
      size: {
        xl: "px-8 py-[22px]",
        lg: "px-8 py-5",
        md: "px-6 py-3.5",
        sm: "px-4 py-3"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "md"
    }
  }
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonVariants {
  buttonClassName?: string;
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      disabled,
      buttonClassName,
      fullWidth,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <div
        className={cn(
          wrapperVariants({
            variant,
            disabled,
            fullWidth,
            size
          }),
          className
        )}
      >
        <Comp
          className={cn(
            buttonVariants({ variant, size, fullWidth }),
            buttonClassName
          )}
          ref={ref}
          disabled={disabled}
          {...props}
        />
      </div>
    );
  }
);
Button.displayName = "Button";

export default Button;
