import { useTranslation } from "react-i18next";
import FriendsSection from "../FriendsSection";
import FriendsStatsItem from "./FriendsStatsItem";
import CoinIcon from "~/assets/icons/Coin.png";
import Referral from "~/assets/icons/Referral.png";

type Props = {
  rewards?: number;
  count?: number;
};

const FriendsStats = ({ rewards = 0, count = 0 }: Props): JSX.Element => {
  const { t } = useTranslation("friends");

  return (
    <FriendsSection>
      <FriendsStatsItem
        label={t("stats.rewards")}
        value={rewards}
        icon={<img src={CoinIcon} className="w-8 h-8" />}
      />
      <FriendsStatsItem
        label={t("stats.referrals")}
        value={count}
        icon={<img src={Referral} className="w-8 h-8" />}
      />
    </FriendsSection>
  );
};

export default FriendsStats;
