/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../declarations/graphql/types';

import { gql } from '@urql/core';
export type DTODailyStreakFragment = { __typename?: 'DailyStreak', id: any, claimed: boolean, current: { __typename?: 'DailyBonus', id: any, day: number, amount: number }, next?: { __typename?: 'NextDailyBonus', availableAt: any, serverTime: any } | null };

export const DailyStreakFragmentDoc = gql`
    fragment DailyStreakFragment on DailyStreak {
  id
  claimed
  current {
    id
    day
    amount
  }
  next {
    availableAt
    serverTime
  }
}
    `;