import { useCallback, useEffect, useRef } from "react";

import OnScrollToDefaultComponent from "./OnScrollToDefaultComponent";

export interface OnScrollToComponentProps<T> {
  loading: boolean;
  ref?: React.LegacyRef<T>;
  className?: string;
}

export interface Props {
  hasMore: boolean;
  loading: boolean;
  distance?: number;
  horizontalDistance?: number;
  onScrollTo?: (isIntersecting: boolean) => Promise<void>;
  root?: HTMLElement | null;
  Component?: React.ComponentType<OnScrollToComponentProps<HTMLDivElement>>;
  className?: string;
  triggerOnEnter?: boolean;
  triggerOnExit?: boolean;
}

interface ObserverRef {
  intersectionObserver: IntersectionObserver | null;
  pendingPromise: Promise<unknown> | null;
}

const OnScrollTo = ({
  onScrollTo,
  hasMore,
  loading,
  distance,
  root,
  className,
  horizontalDistance = 0,
  Component = OnScrollToDefaultComponent,
  triggerOnEnter = true,
  triggerOnExit = false
}: Props): JSX.Element | null => {
  const componentRef = useRef<HTMLDivElement | null>(null);
  const ref = useRef<ObserverRef>({
    intersectionObserver: null,
    pendingPromise: null
  });

  const enable = useCallback((): void => {
    if (ref.current.intersectionObserver && componentRef.current) {
      ref.current.intersectionObserver.observe(componentRef.current);
    }
  }, []);

  const disable = useCallback((): void => {
    if (ref.current.intersectionObserver && componentRef.current) {
      ref.current.intersectionObserver.disconnect();
    }
  }, []);

  const handleIntersection = useCallback(
    async (entries: IntersectionObserverEntry[]): Promise<void> => {
      if (
        !entries.length ||
        !entries.some(
          entry =>
            (triggerOnEnter && entry.isIntersecting) ||
            (triggerOnExit && !entry.isIntersecting)
        )
      ) {
        return;
      }

      if (onScrollTo && !ref.current.pendingPromise && !loading && hasMore) {
        ref.current.pendingPromise = onScrollTo(entries[0].isIntersecting);
        await ref.current.pendingPromise;
        ref.current.pendingPromise = null;
      }
    },
    [hasMore, loading, onScrollTo, triggerOnEnter, triggerOnExit]
  );

  useEffect(() => {
    if (!componentRef.current) {
      return;
    }

    const verticalMargin = distance ?? window.innerHeight;
    const horizontalMargin = horizontalDistance;

    disable();
    ref.current.intersectionObserver = new IntersectionObserver(
      handleIntersection,
      {
        root: root ?? null,
        rootMargin: `${verticalMargin}px ${horizontalMargin}px ${verticalMargin}px ${horizontalMargin}px`,
        threshold: 0
      }
    );
    enable();
  }, [disable, distance, enable, handleIntersection, horizontalDistance, root]);

  if (!hasMore) {
    return null;
  }

  return (
    <Component className={className} loading={loading} ref={componentRef} />
  );
};

export default OnScrollTo;
