import { useLaunchParams } from "@telegram-apps/sdk-react";

const PLATFORMS_FOR_WHICH_APP_IS_AVAILABLE = new Set(["android", "ios"]);

export const useAppAvailableForPlatform = () => {
  const { platform } = useLaunchParams();

  if (
    process.env.NODE_ENV === "development" ||
    import.meta.env.VITE_ENABLE_IN_ALL_PLATFORMS === "true"
  ) {
    return true;
  }

  return PLATFORMS_FOR_WHICH_APP_IS_AVAILABLE.has(platform);
};
