import "./App.css";
import { FC, useEffect, useState } from "react";
import { InitialAppLoader } from "~/components/ui/loaders/InitialAppLoader/InitialAppLoader";
import { useReady } from "~/utils/telegram/useReady";
import { wait } from "~/utils/async";
import { MILLISECONDS_IN_SECOND } from "~/constants/time";
import { useMeQuery } from "~/graphql/useMe/graphql/meQuery.generated";
import { useTasksQuery } from "~/components/rewards/Rewards/Tasks/useTasks/graphql/tasksQuery.generated";
import { useDailyBonusesQuery } from "~/components/dailyBonus/DailyBonuses/useDailyBonuses/graphql/dialyBonusesQuery.generated";
import { useAppAvailableForPlatform } from "~/utils/telegram/useAppAvailableForPlatform";
import { QRCodeWithLinkToBot } from "~/components/onboarding/QRCodeWithLinkToBot/QRCodeWithLinkToBot";
import { useReferralRewardsQuery } from "./components/friends/graphql/referralRewards.generated";
import { useRefereesQuery } from "./components/friends/graphql/refereesQuery.generated";
import { REFEREES_PER_BATCH } from "./components/friends/Friends/Friends";
import useReferer from "./graphql/useReferer/useReferer";
import { FARMING_ANIMATION_PAYLOAD } from "~/components/common/SpinePlayer/ImmortalSpinePlayer/constants";
import { useLoginMutation } from "~/graphql/useLogin/graphql/loginMutation.generated";
import { retrieveLaunchParams } from "@telegram-apps/sdk";

const TIMEOUT_JUST_FOR_IMPRESSION: Milliseconds = 2 * MILLISECONDS_IN_SECOND;
const TIMEOUT_TO_SHOW_FILLED_LOADER: Milliseconds = 300;

function App() {
  useReady();

  const { initDataRaw } = retrieveLaunchParams();
  const [_loginResult, login] = useLoginMutation();
  const [me, fetchMe] = useMeQuery({
    pause: true
  });
  const [_tasks, fetchTasks] = useTasksQuery({
    pause: true
  });
  const [_dailyBonuses, fetchDailyBonuses] = useDailyBonusesQuery({
    pause: true
  });
  const [_referralRewards, fetchReferralRewards] = useReferralRewardsQuery({
    pause: true
  });
  const [_referees, fetchReferees] = useRefereesQuery({
    variables: {
      first: REFEREES_PER_BATCH
    },
    pause: true
  });
  const isAppAvailableForPlatform = useAppAvailableForPlatform();

  const [AppContent, setAppContent] = useState<FC | null>(null);
  const [isLoaderShown, setIsLoaderShown] = useState(true);
  const [isLoadingDone, setIsLoadingDone] = useState(false);

  useReferer(me);

  useEffect(() => {
    if (!isAppAvailableForPlatform) {
      return;
    }

    const loadAppContent = async () => {
      const module = await import("~/AppContent");
      const AppContent = module.default;

      setAppContent(() => AppContent);
    };

    const loadResources = async () => {
      await login({
        initData: initDataRaw
      });

      const queriesToPrefetch = [
        fetchMe(),
        fetchTasks(),
        fetchDailyBonuses(),
        fetchReferralRewards(),
        fetchReferees(),
        FARMING_ANIMATION_PAYLOAD
      ];
      await Promise.all([
        ...queriesToPrefetch,
        loadAppContent(),
        wait(TIMEOUT_JUST_FOR_IMPRESSION)
      ]);
      setIsLoadingDone(true);
      await wait(TIMEOUT_TO_SHOW_FILLED_LOADER);
      setIsLoaderShown(false);
    };

    loadResources();
  }, [
    fetchDailyBonuses,
    fetchMe,
    fetchReferralRewards,
    fetchTasks,
    fetchReferees,
    login,
    isAppAvailableForPlatform,
    initDataRaw
  ]);

  if (!isAppAvailableForPlatform) {
    return <QRCodeWithLinkToBot />;
  }

  if (isLoaderShown) {
    return (
      <InitialAppLoader
        isDone={isLoadingDone}
        className="max-w-[744px] text-red"
      />
    );
  }

  if (AppContent) {
    return <AppContent />;
  }

  return null;
}

export default App;
