import { initMiniApp, initBackButton } from "@telegram-apps/sdk";
import { useEffect } from "react";

const [miniApp] = initMiniApp();
const [backButton] = initBackButton();

export const useReady = () => {
  useEffect(() => {
    miniApp.ready();
    backButton.hide();
  }, []);
};
