import { useTranslation } from "react-i18next";
import LogoIcon from "~/assets/icons/Logo.svg?react";
import { Loader } from "~/components/ui/loaders/Loader";

type Props = {
  isDone: boolean;
  className?: string;
};

export const InitialAppLoader = ({ isDone, className }: Props) => {
  const { t } = useTranslation("common");

  return (
    <Loader isDone={isDone} className={className}>
      <LogoIcon
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        width={88}
        height={88}
      />
      <div className="flex flex-col gap-1 py-6 px-8 text-center mt-auto">
        <h2 className="t-h2">{t("loading.title")}</h2>
        <div className="t-p-quaternary text-foreground-tertiary">
          {t("loading.text")}
        </div>
      </div>
    </Loader>
  );
};
