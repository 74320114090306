import { ErrorPlaceholder } from "~/components/errors/ErrorPlaceholder/ErrorPlaceholder";
import { cn } from "~/utils";
import * as Sentry from "@sentry/react";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren;

export const ErrorBoundary = ({ children }: Props) => {
  const handleTryAgain = () => {
    window.location.reload();
  };

  return (
    <Sentry.ErrorBoundary
      fallback={
        <div className={cn("fixed w-full h-full top-0 left-0")}>
          <ErrorPlaceholder onTryAgain={handleTryAgain} />
        </div>
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
