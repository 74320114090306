/* eslint-disable */
// @ts-nocheck
import * as Types from '../../declarations/graphql/types';

import { gql } from '@urql/core';
export type DTOPageInfoFragment = { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, endCursor: string, startCursor: string };

export const PageInfoFragmentDoc = gql`
    fragment PageInfoFragment on PageInfo {
  hasNextPage
  hasPreviousPage
  endCursor
  startCursor
}
    `;