import { DTOReferee, Referral } from "~/components/friends/declarations";

export default function mapDTORefereesToReferrals(
  dto: DTOReferee[]
): Referral[] {
  return dto.map(({ id, points, profile }) => ({
    id,
    reward: points,
    level: profile?.level ?? 1,
    name: profile?.nickname ?? ""
  }));
}
