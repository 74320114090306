import LVL1Image from "./assets/LVL1.png";
import LVL2Image from "./assets/LVL2.png";
import LVL3Image from "./assets/LVL3.png";
import LVL4Image from "./assets/LVL4.png";
import LVL5Image from "./assets/LVL5.png";
import LVL6Image from "./assets/LVL6.png";
import LVL7Image from "./assets/LVL7.png";
import LVL8Image from "./assets/LVL8.png";
import LVL9Image from "./assets/LVL9.png";
import LVL10Image from "./assets/LVL10.png";
import LVL11Image from "./assets/LVL11.png";
import LVL12Image from "./assets/LVL12.png";
import LVL13Image from "./assets/LVL13.png";
import LVL14Image from "./assets/LVL14.png";
import LVL15Image from "./assets/LVL15.png";
import LVL16Image from "./assets/LVL16.png";
import LVL17Image from "./assets/LVL17.png";
import LVL18Image from "./assets/LVL18.png";
import LVL19Image from "./assets/LVL19.png";
import LVL20Image from "./assets/LVL20.png";
import LVL21Image from "./assets/LVL21.png";
import LVL22Image from "./assets/LVL22.png";
import LVL23Image from "./assets/LVL23.png";
import LVL24Image from "./assets/LVL24.png";
import LVL25Image from "./assets/LVL25.png";
import LVL26Image from "./assets/LVL26.png";
import LVL27Image from "./assets/LVL27.png";
import LVL28Image from "./assets/LVL28.png";
import LVL29Image from "./assets/LVL29.png";
import LVL30Image from "./assets/LVL30.png";
import { cn } from "~/utils";
import { BoostLevelNumber } from "~/declarations/level";

const LEVEL_IMAGES_BY_LEVEL_NUMBER: Record<BoostLevelNumber, string> = {
  1: LVL1Image,
  2: LVL2Image,
  3: LVL3Image,
  4: LVL4Image,
  5: LVL5Image,
  6: LVL6Image,
  7: LVL7Image,
  8: LVL8Image,
  9: LVL9Image,
  10: LVL10Image,
  11: LVL11Image,
  12: LVL12Image,
  13: LVL13Image,
  14: LVL14Image,
  15: LVL15Image,
  16: LVL16Image,
  17: LVL17Image,
  18: LVL18Image,
  19: LVL19Image,
  20: LVL20Image,
  21: LVL21Image,
  22: LVL22Image,
  23: LVL23Image,
  24: LVL24Image,
  25: LVL25Image,
  26: LVL26Image,
  27: LVL27Image,
  28: LVL28Image,
  29: LVL29Image,
  30: LVL30Image
};

type Props = {
  level: BoostLevelNumber;
  className?: string;
};

const LevelImage = ({ level, className }: Props): JSX.Element => {
  return (
    <div
      className={cn(
        "flex items-center justify-center aspect-square",
        className
      )}
    >
      <img src={LEVEL_IMAGES_BY_LEVEL_NUMBER[level]} />
    </div>
  );
};

export default LevelImage;
