import { Skeleton } from "~/components/ui/skeleton";
import { cn } from "~/utils";

interface Props {
  className?: string;
}

const OnScrollToDotsLoader = ({ className }: Props): JSX.Element => (
  <div className={cn(className, "flex items-center justify-center gap-3")}>
    <Skeleton className="rounded-full w-4 h-4 bg-background-senary" />
    <Skeleton className="rounded-full w-4 h-4 bg-background-senary" />
    <Skeleton className="rounded-full w-4 h-4 bg-background-senary" />
  </div>
);
export default OnScrollToDotsLoader;
