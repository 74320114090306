import PageHeader from "~/components/layouts/PageHeader";
import Top from "../assets/Top.png";
import { Trans, useTranslation } from "react-i18next";
import { Drawer, DrawerTrigger } from "~/components/ui/drawer";
import FriendsHeaderHowItWorks from "./FriendsHeaderHowItWorks/FriendsHeaderHowItWorks";
import { FriendBonuses } from "~/components/friends/FriendsHeader/types";

type Props = {
  bonuses: FriendBonuses;
};

const FriendsHeader = ({ bonuses }: Props): JSX.Element => {
  const { t } = useTranslation("friends");

  return (
    <Drawer>
      <PageHeader
        className="pb-3"
        img={Top}
        title={t("title")}
        text={
          <Trans
            t={t}
            i18nKey={"text"}
            components={{
              Link: (
                <DrawerTrigger
                  className={`
                    p-0 focus:outline-none hover:border-none
                    active:bg-transparent active:text-secondary-dark
                    bg-transparent border-none text-secondary t-title-quaternary font-bold
                  `}
                />
              )
            }}
          />
        }
      />
      <FriendsHeaderHowItWorks bonuses={bonuses} />
    </Drawer>
  );
};

export default FriendsHeader;
