import { ReactNode } from "react";
import BackgroundGrid from "./assets/BackgroundGrid.png";
import { cn } from "~/utils";

type Props = {
  children: ReactNode;
  className?: string;
};

const PageWithBackground = ({ children, className }: Props): JSX.Element => {
  return (
    <div
      className={"h-full w-full top-0 bg-no-repeat bg-cover overflow-hidden"}
      style={{ backgroundImage: `url(${BackgroundGrid})` }}
    >
      <div
        className={`
          absolute w-[304px] h-[288px] top-[3px] left-[-24px] 
          bg-primary rounded-full opacity-[17%] blur-[100px]
        `}
      />
      <div
        className={`
          absolute w-[304px] h-[288px] bottom-5 right-[-83px] 
          bg-[#BB9BFF] rounded-full opacity-[20%] blur-[130px]
        `}
      />
      <div
        className={cn(
          `relative w-full h-full overflow-y-auto 
           scrollbar-gutter-stable scrollbar-gutter-both-edges`,
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default PageWithBackground;
