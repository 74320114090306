import { DocumentNode } from "graphql";
import { AnyVariables, Client, CombinedError, OperationContext } from "urql";

import { RequestState } from "~/constants/requestState";

type FetchResult<TQueryResult> =
  | { type: RequestState.Error; error: CombinedError }
  | { type: RequestState.Success; data: TQueryResult };

const wrapQuery = async <
  TQueryResult extends Record<string, unknown>,
  TQueryVariables extends AnyVariables = AnyVariables
>(
  graphqlClient: Client,
  query: DocumentNode,
  variables?: TQueryVariables,
  context?: Partial<OperationContext>
): Promise<FetchResult<TQueryResult>> => {
  try {
    const { data, error } = await graphqlClient
      .query<TQueryResult, AnyVariables>(query, variables, context)
      .toPromise();

    if (error || !data) {
      const combinedError = error
        ? error
        : new CombinedError({
            networkError: new Error("No error object")
          });

      return {
        type: RequestState.Error,
        error: combinedError
      };
    }

    return {
      type: RequestState.Success,
      data
    };
  } catch (error) {
    return {
      type: RequestState.Error,
      error: new CombinedError({
        networkError: new Error(JSON.stringify(error))
      })
    };
  }
};

export default wrapQuery;
