/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../../../declarations/graphql/types';

import { DTOTaskFragment } from './taskFragment.generated';
import { gql } from '@urql/core';
import { TaskFragmentDoc } from './taskFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOTasksQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DTOTasksQuery = { __typename?: 'Query', tasks: { __typename?: 'Tasks', edges: Array<{ __typename?: 'TaskEdge', node: (
        { __typename?: 'Task' }
        & DTOTaskFragment
      ) }> } };


export const TasksQueryDocument = gql`
    query TasksQuery {
  tasks {
    edges {
      node {
        ...TaskFragment
      }
    }
  }
}
    ${TaskFragmentDoc}`;

export function useTasksQuery(options?: Omit<Urql.UseQueryArgs<DTOTasksQueryVariables>, 'query'>) {
  return Urql.useQuery<DTOTasksQuery, DTOTasksQueryVariables>({ query: TasksQueryDocument, ...options });
};