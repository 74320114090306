export default async function copyToClipboardViaAsyncApi(
  text: string
): Promise<boolean> {
  try {
    navigator.clipboard.writeText(text);

    return true;
  } catch {
    console.error("Copy to clipboard failed");

    return false;
  }
}
