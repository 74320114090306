import { UsePaginationResult } from "~/utils/graphql/usePagination/declarations";
import { DTOReferee, Referral } from "../declarations";
import { RequestState } from "~/constants/requestState";
import { useTranslation } from "react-i18next";
import FriendsReferral from "./FriendsReferral";
import OnScrollTo from "~/components/common/OnScrollTo";

type Props = {
  refereesResult: UsePaginationResult<DTOReferee, Referral>;
};

const FriendsReferrals = ({ refereesResult }: Props): JSX.Element | null => {
  const { t } = useTranslation("friends");

  if (refereesResult.type === RequestState.Success) {
    return (
      <>
        <div className="t-title-tertiary pt-2 pb-3 font-bold">
          {t("myFriends")}
        </div>
        {refereesResult.recordsMapped.map(referral => (
          <FriendsReferral key={referral.id} {...referral} />
        ))}
        <OnScrollTo
          onScrollTo={refereesResult.fetchMore}
          hasMore={refereesResult.hasMore}
          loading={refereesResult.fetchingMore}
        />
      </>
    );
  }

  return null;
};

export default FriendsReferrals;
