import { Referral } from "../declarations";
import CoinIcon from "~/assets/icons/Coin.png";
import { formatTokens } from "~/components/balance/Tokens/utils";
import LevelImage from "~/components/common/LevelImage";

const FriendsReferral = ({ level, name, reward }: Referral): JSX.Element => {
  return (
    <div className="rounded-24 py-3 pl-3 pr-4 mb-1 bg-white/4 flex items-center gap-[10px] backdrop-blur-md">
      <LevelImage level={level} className="rounded-full w-10 h-10" />
      <span className="t-h6 overflow-hidden text-ellipsis w-full flex-shrink">
        {name}
      </span>
      <img src={CoinIcon} className="ml-auto mr-2 w-6 h-6" />
      <span className="t-h6 font-regular">+{formatTokens(reward)}</span>
    </div>
  );
};

export default FriendsReferral;
